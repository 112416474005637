<template>
  <span
    class="d-flex justify-content-center text-center font-weight-bold text-white"
    style="font-size:1.2rem"
  >
    Made with &nbsp;<span class="text-danger">❤</span>&nbsp; by <div
      v-if="loading"
      class="spinner-border"
      role="status"
    >
      <span class="sr-only">Loading...</span>
    </div><div v-else>&nbsp;{{ business.company_name }}</div>
  </span>
</template>

<script>
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const trackingRepository = RepositoryFactory.get('tracking')

export default {
  data() {
    return {
      business: {},
      loading: false,
    }
  },
  mounted() {
    this.getBusinessInfo()
  },
  methods: {
    async getBusinessInfo() {
      try {
        this.loading = true
        this.business = localStorage.getItem('businessSettings') ? JSON.parse(localStorage.getItem('businessSettings')) : (await trackingRepository.getBusinessInfo()).data.data
        localStorage.setItem('businessSettings', JSON.stringify(this.business))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
  },
}
</script>
